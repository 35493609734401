import React from 'react';
import PropTypes from 'prop-types';
import CareerCard from './career-card';
import CTA from '../global/cta';

function CareerCardList(props) {
  return (
    <>
      {props.nodes && props.nodes.map((node, index) => <CareerCard {...node} key={index} />)}

      <CTA />
    </>
  );
}

CareerCardList.propTypes = {
  nodes: PropTypes.array
};

export default CareerCardList;
