import { graphql } from 'gatsby';
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import CareerCardList from '../components/careers/career-card-list';
import ContactSidebar from '../components/contact/contact-sidebar';
import Container from '../components/global/container';
import CTA from '../components/global/cta';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import Layout from '../containers/layout';
import { buildImageObj, mapEdgesToNodes } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';

export const query = graphql`
  query CareersPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      phone
      email
      address
      map
    }
    static: sanityPage(slug: { current: { eq: "contact-careers-page" } }) {
      _rawHeading
      _rawIntro
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      media {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            _id
            url
          }
        }
        alt
      }
    }
    careers: allSanityCareers(sort: { fields: order }) {
      edges {
        node {
          _id
          order
          title
          _rawDescription
          team {
            team {
              email
            }
          }
        }
      }
    }
  }
`;

const CareersPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const content = (data || {}).static;
  const media = content.media;
  const careers = (data || {}).careers ? mapEdgesToNodes(data.careers) : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
      {content.seo && (
          <SEO
          title={content.seo.title !== null ? content.seo.title : false}
          description={content.seo.description !== null ? content.seo.description : false}
          keywords={content.seo.keywords !== null ? content.seo.keywords : false}
          image={content.seo.image !== null ? content.seo.image : false}
        />
        )}
        <Container>
            <section className={`section careers--page contact ${inView ? 'in-view' : ''}`} ref={ref}>
              <div className="site-wide">
                <div className="hero hero--animation">
                    <h2>Careers</h2>
                </div>
                <div className="grid-container grid-container--4-2">
                  <div className="grid-column contact__tabs-container">
                    {careers && <CareerCardList nodes={careers} />}
                  </div>

                  <div className="grid-column sidebar">
                    <div className='careers-intro'>
                        <h3>We're always happy to hear from talented individuals, if you feel like you're a missing part of the team, get in touch:</h3>
                    </div>
                    <ContactSidebar
                      address={site.address}
                      email={site.email}
                      phone={site.phone}
                      map={site.map}
                    />
                  </div>
                </div>
              </div>
            </section>
        </Container>
      </Layout>
    </>
  );
};

export default CareersPage;
