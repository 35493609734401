import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Pill from '../../components/global/elements/buttons/pill';
import PortableText from '../portableText';

function CareerCard(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.10
  });

  const [setActive, setActiveState] = useState("");
  const accordion = useRef();

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
  }

  return (
    <div className={`career ${inView ? 'in-view' : ''}`} ref={ref}>
      <div className="career__tab">
        <p className="small">Apply for this role</p>
      </div>

      <div className={`career__details ${setActive}`} ref={accordion}>
        <h3 className="h1-styling">{props.title}</h3>
        <div className='career__description'>
          {props._rawDescription && <PortableText blocks={props._rawDescription} />}
        </div>
        <Pill link={props.team.team.email} />
        <button
          onClick={toggleAccordion}
          className="btn expand--button"
        >Expand to see more</button>
      </div>
    </div>
  );
}

CareerCard.propTypes = {
  title: PropTypes.string,
  _rawDescription: PropTypes.array,
  team: PropTypes.object
};

export default CareerCard;
