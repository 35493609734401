import React from 'react';
import PropTypes from 'prop-types';

const Pill = ({ link }) => {
  return (
    <a href={`mailto:${link}`} className="btn-pill">
      To apply, contact: <strong>{link}</strong>
    </a>
  );
};

Pill.propTypes = {
  link: PropTypes.string
};

export default Pill;
